import Combobox, { ComboboxProps } from './Combobox';
import React from 'react';
import { ComboboxItem } from './types';
import { stringify } from 'qs';

interface DefaultApiItem {
  id: string;
  name: string;
}
export function defaultNormalizer(item: DefaultApiItem): ComboboxItem {
  return {
    id: item.id,
    label: item.name,
  };
}

export type ApiComboboxProps<ApiItem> = Omit<
  ComboboxProps,
  'loadSelectItems'
> & {
  normalizer: (item: ApiItem) => ComboboxItem;
  url: string;
  filters?: Record<string, string>;
  includes?: string[];
  orders?: string[];
  fields?: Record<string, string>;
  apiClient: (url: string, options: RequestInit) => Promise<Response>;
};

const ITEMS_PER_LIST = 15;

async function loadSelectItemsFromApi<ApiItem>(
  abortController: AbortController | null,
  inputValue: string | null,
  page: number,
  url: string,
  apiClient: (url: string, options: RequestInit) => Promise<Response>,
  normalizer: (item: ApiItem) => any,
  filters?: Record<string, string>,
  includes?: string[],
  orders?: string[],
  fields?: Record<string, string>
): Promise<[ComboboxItem[], number]> {
  let baseOptions: RequestInit = {};
  if (abortController) {
    baseOptions.signal = abortController.signal;
  }

  if (abortController && abortController.signal.aborted) {
    return [[], 0];
  }

  let queryString: any = {
    page: page,
    items: ITEMS_PER_LIST,
    countTotalResults: '1',
    filters: {
      ...filters,
      textQuery: inputValue,
      //search: inputValue,
    },
  };

  if (fields) {
    queryString.fields = fields;
  }

  if (includes) {
    queryString.includes = includes.join(',');
  }

  if (orders) {
    queryString.order = orders.join(',');
  }

  let searchResult = null;
  try {
    searchResult = await apiClient(
      `${url}?${stringify(queryString)}`,
      baseOptions
    ).then(response => response.json());
  } catch (error) {
    if (error?.name === 'AbortError') {
      // silently catch abort error error since aborted request are treated as error
      return [[], 0];
    }

    throw error;
  }

  const loadedItems = searchResult.items.map((item: ApiItem) => ({
    item,
    ...normalizer(item),
  }));

  return [loadedItems, searchResult.resultNumber];
}

export default function ApiCombobox<ApiItem, DefaultApiItem = ApiItem>({
  filters,
  normalizer = defaultNormalizer as (item: ApiItem) => ComboboxItem,
  url,
  includes,
  orders,
  fields,
  apiClient = fetch,
  ...otherProps
}: ApiComboboxProps<ApiItem>) {
  return (
    <Combobox
      loadSelectItems={async function(
        abortController: AbortController | null,
        inputValue: string | null,
        page: number
      ) {
        return await loadSelectItemsFromApi(
          abortController,
          inputValue,
          page,
          url,
          apiClient,
          normalizer,
          filters,
          includes,
          orders,
          fields
        );
      }}
      {...otherProps}
    />
  );
}
